import React from "react";
import { addToAirtable } from "./airtableEmail.js";
import { navigate } from "gatsby";
import { useTranslation } from "react-i18next";

const Newsletter = () => {
  const { t } = useTranslation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const email = event.target.elements[0].value;
    await addToAirtable(email);
    navigate("/confirmed"); // Navigate to the /confirmed page
  };

  return (
    <div class="section newsletter wf-section" style={{ marginTop: "100px" }}>
      <div class="container">
        <div class="newsletter-wrapper">
          <div class="vertical-container">
            <div class="horizontal-container">
              <img
                src="https://assets.website-files.com/6246f628ff60cf28f937a441/624e1569ea17fcd2bfdaeef0_send.svg"
                loading="lazy"
                alt=""
              />
              <h3 class="h3 left-margin">{t("newsletter.heading")}</h3>
            </div>
            <p class="text width">{t("newsletter.description")}</p>
          </div>
          <div class="vertical-container">
            <div class="form-newsletter">
              <form onSubmit={handleSubmit}>
                <label class="label">{t("newsletter.email")}</label>
                <div class="horizontal-container newsletter">
                  <input
                    type="email"
                    class="text-field margin w-input"
                    maxlength="256"
                    required
                  />
                  <input
                    type="submit"
                    name="submit"
                    class="primary-button submit w-button"
                    value={t("newsletter.submit")}
                  />
                </div>
                <label class="w-checkbox checkbox-field" required>
                  <input
                    type="checkbox"
                    id="checkbox"
                    name="checkbox"
                    data-name="Checkbox"
                    required="on"
                  />
                  <span class="checkbox-label w-form-label" for="checkbox">
                    {t("newsletter.didYouRead")}
                    <a
                      href="/privacy"
                      class="newsletter-link"
                      style={{ marginLeft: "10px" }}
                    >
                      {t("newsletter.privacyPolicy")}
                    </a>{" "}
                  </span>
                </label>
                <label class="w-checkbox checkbox-field" required>
                  <input
                    type="checkbox"
                    id="checkbox"
                    name="checkbox"
                    data-name="Checkbox"
                    required="on"
                  />
                  <span class="checkbox-label w-form-label" for="checkbox">
                    {t("newsletter.didYouRead")}
                    <a
                      href="/explicit"
                      class="newsletter-link"
                      style={{ marginLeft: "10px" }}
                    >
                      {t("newsletter.explicitConsent")}
                    </a>{" "}
                  </span>
                </label>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
