import React from "react";

import Newsletter from "../components/Newsletter/Newsletter.jsx";
import ContentList from "../components/ContentList";

export default function index() {
  return (
    <div>
      <ContentList />

      <Newsletter />
    </div>
  );
}
