/* eslint-disable no-undef, react/prop-types, react/no-danger */
import React from "react";
import SEO from "../components/SEO/SEO";
import blog from "../../data/data-blog.json";

const Template = ({ pageContext }) => {
  const { airtableData } = pageContext;
  const newBlogSelector = blog.filter(
    (item) => item.description === airtableData.description
  );
  const newBlog = newBlogSelector[0];

  return (
    <>
      {newBlog && (
        <SEO title={newBlog.title} description={newBlog.description} />
      )}
      <div class="section margin wf-section">
        <div class="container">
          <div
            data-w-id="bff9d3c6-caa3-3796-e08d-a48fa1ce7e28"
            class="center-container bottom-margin"
          >
            <div class="horizontal-container">
              <div class="bold-text right-margin">-</div>
              <div class="bold-text">{newBlog.category}</div>
              <div class="bold-text left-margin">-</div>
            </div>
            <h1 class="h1 titlecase">{newBlog.title}</h1>
            <div class="horizontal-container">
              <div class="small-text">By</div>
              <div class="small-text author">{"LingoEdit Team"}</div>
            </div>
          </div>
          <div class="main-image">
            <img
              data-w-id="59718b17-ca90-2e75-0d9f-d3af496ddee2"
              loading="lazy"
              src={newBlog.image + "public"}
              alt=""
              sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 90vw, (max-width: 1919px) 780px, 980px"
              class="image"
            />
          </div>
          <div class="rich-text w-richtext">
            <h1>{newBlog.title}</h1>
            <p>{newBlog.intro}</p>
            <h2>{newBlog.section1Headline}</h2>
            <p>{newBlog.section1Content}</p>
            <h2>{newBlog.section2Headline}</h2>
            <p>{newBlog.section2Content}</p>
            <h2>{newBlog.section3Headline}</h2>
            <p>{newBlog.section3Content}</p>
            <h2>{newBlog.section4Headline}</h2>
            <p>{newBlog.section4Content}</p>
            <h2>{newBlog.section5Headline}</h2>
            <p>{newBlog.section5Content}</p>
            <h2>{newBlog.section6Headline}</h2>
            <p>{newBlog.section6Content}</p>
            <h2>{newBlog.section7Headline}</h2>
            <p>{newBlog.section7Content}</p>
            <h2>{newBlog.section8Headline}</h2>
            <p>{newBlog.section8Content}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Template;
